// Layouts
import { PrintLayout } from '~/components/Layout';
import Table from '~/pages/Table';
import TableDetail from '~/pages/Table/TableDetail';
import Booking from '~/pages/Table/TableDetail/Booking';
import PrintBill from '~/pages/Print/PrintBill';
import PrintProcessing from '~/pages/Print/PrintProcessing';
import BookingDetail from '~/pages/Table/TableDetail/Booking/BookingDetail';
import AdminStore from '~/pages/Admin/Store';
import Bbq from '~/pages/Bbq';
import BbqFinish from '~/pages/Bbq/BbqFinish';
import Ticket from '~/pages/Ticket';
import PrintTicket from '~/pages/Print/PrintTicket';

import AdminBbqList from '~/pages/Admin/BbqList';
import AdminBbqDetail from '~/pages/Admin/BbqList/BbqDetail';
import AdminBbqEdit from '~/pages/Admin/BbqList/BbqEdit';
import AdminBbqPrintProcessing from '~/pages/Admin/BbqList/BbqDetail/BbqPrintProcessing';
import AdminSetting from '~/pages/Admin/Setting';
import AdminIngredient from '~/pages/Admin/Ingredient';
import AdminProduct from '~/pages/Admin/Product';
import AdminStatistic from '~/pages/Admin/Statistic';
import AdminBill from '~/pages/Admin/Bill';
import AdminOrder from '~/pages/Admin/Order';
import AdminTicket from '~/pages/Admin/Ticket';
import AdminCamping from '~/pages/Admin/Camping';
import AdminLoyalCustomer from '~/pages/Admin/LoyalCustomer';
import AdminWater from '~/pages/Admin/Water';
import AdminSellerCustomer from '~/pages/Admin/SellerCustomer';
const publicRoutes = [
    { path: '/table-plan', component: Table },
    { path: '/table-detail/:type/:tableId/:objId?', component: TableDetail },
    { path: '/table-booking/:type/:tableId/:objId?/:orderId', component: Booking },
    { path: '/booking-detail/:tableId/:orderId', component: BookingDetail },
    { path: '/print-ticket/:ticketId', component: PrintTicket, layout: PrintLayout },
    { path: '/print-bill/:type/:tableId/:objId', component: PrintBill, layout: PrintLayout },
    { path: '/print-processing/:orderId', component: PrintProcessing, layout: PrintLayout },
    //TICKET
    { path: '/', component: Ticket },
    //BBQ
    { path: '/bbq', component: Bbq },
    { path: '/bbq/:bbqId', component: BbqFinish },
];

const privateRoutes = [
    //private routes
    { path: '/super-admin', component: AdminStatistic },
    { path: '/super-admin/orders', component: AdminOrder },
    { path: '/super-admin/bbqs', component: AdminBbqList },
    { path: '/super-admin/bbq/:bbqId', component: AdminBbqDetail },
    { path: '/super-admin/bbq-edit/:bbqId', component: AdminBbqEdit },
    { path: '/super-admin/bbq-print-processing/:bbqId', component: AdminBbqPrintProcessing, layout: PrintLayout },
    { path: '/super-admin/ingredients', component: AdminIngredient },
    { path: '/super-admin/bills', component: AdminBill },
    { path: '/super-admin/tickets', component: AdminTicket },
    { path: '/super-admin/loyal-customers', component: AdminLoyalCustomer },
    { path: '/super-admin/seller-customers', component: AdminSellerCustomer },
    { path: '/super-admin/waters', component: AdminWater },
    { path: '/super-admin/camping', component: AdminCamping },
    { path: '/super-admin/products', component: AdminProduct },
    { path: '/super-admin/stores', component: AdminStore },
    { path: '/super-admin/settings', component: AdminSetting },
];

export { publicRoutes, privateRoutes };
