import { useEffect, useState } from 'react';
import TableItem from './TableItem';
import * as tableServices from '~/apiServices/tableServices';
import classNames from 'classnames/bind';
import styles from '~/components/Table/Table.module.scss';
import Modal from '../Modal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChildren, faClose, faPeopleGroup, faPhone, faSignature } from '@fortawesome/free-solid-svg-icons';
import { orderTag } from '~/utils/filters';
const cx = classNames.bind(styles);

function Table() {
    const naviate = useNavigate();
    const [tableList, setTableList] = useState([]);
    const [show, setShow] = useState(false);
    const [table, setTable] = useState({});
    const [orders, setOrders] = useState([]);

    const [popup, setPopup] = useState(false);
    const [name, setName] = useState('Khách lẻ');
    const [phone, setPhone] = useState('0907891414');
    const [adt, setAdt] = useState(1);
    const [chd, setChd] = useState(0);
    useEffect(() => {
        const fetchTables = async () => {
            const response = await tableServices.getTables();
            if (response.length > 0) {
                setTableList([...tableList, ...response]);
            }
        };
        fetchTables();
    }, []);

    const fetchOrders = async (tableId) => {
        const response = await tableServices.getOrderBbqToday({
            params: {
                table_id: Number(tableId),
            },
        });
        if (response) {
            setOrders(response);
        }
    };

    const handleClickDetail = (table) => {
        setShow(true);
        setTable(table);
        fetchOrders(table.table_id);
    };

    const handleClickNewOrderFit = (table) => {
        const apiAdd = async () => {
            const res = await tableServices.addCustomerFit({
                table_id: Number(table.table_id),
            });
            if (res) {
                const fitId = res.data;
                if (fitId) naviate(`/table-detail/fit/${table.table_id}/${fitId}`);
            } else {
                alert(res.message);
            }
        };
        apiAdd();
    };
    const handleShowPopupBbq = () => {
        document.body.style.overflow = 'hidden';
        setPopup(true);
    };
    const handleClickNewOrderBbq = () => {
        const apiAdd = async () => {
            const res = await tableServices.addCustomerBbq({
                table_id: Number(table.table_id),
                name: name,
                phone: phone,
                adt: adt,
                chd: chd,
            });
            if (res) {
                const bbqId = res.data;
                if (bbqId) naviate(`/table-detail/bbq/${table.table_id}/${bbqId}`);
            } else {
                alert(res.message);
            }
        };
        apiAdd();
    };

    const handleClosePopup = () => {
        document.body.style.overflow = 'visible';
        setPopup(false);
    };

    const handleClickToOrder = (order) => {
        const apiUpdate = async () => {
            const response = await tableServices.updateStatus({
                table_id: Number(order.table_id),
                status: 1,
            });
            if (response) {
                if (order.type === 'fit') {
                    naviate(`/table-detail/fit/${order.table_id}/${order.id}`);
                } else if (order.type === 'bbq') {
                    naviate(`/table-detail/bbq/${order.table_id}/${order.id}`);
                } else if (order.type === 'ticket') {
                    naviate(`/table-detail/ticket/${order.table_id}/${order.id}`);
                }
            }
        };
        apiUpdate();
    };
    return (
        <>
            <div className={cx('table-row')}>
                {tableList.map((item, index) => {
                    return <TableItem key={index} item={item} onClick={handleClickDetail} />;
                })}
            </div>
            <Modal title={`Mở ${table.table_name}`} show={show} setShow={setShow}>
                <ul>
                    <li className="new-water" onClick={() => handleClickNewOrderFit(table)}>
                        <small>
                            <FontAwesomeIcon icon={faAdd} />
                        </small>
                        <small> Khách mới - NƯỚC</small>
                    </li>
                    <li className="new-bbq" onClick={() => handleShowPopupBbq(table)}>
                        <small>
                            <FontAwesomeIcon icon={faAdd} />
                        </small>
                        <small> Khách mới - BBQ</small>
                    </li>
                    {orders.map((order, index) => {
                        return (
                            <li key={index} className={order.type} onClick={() => handleClickToOrder(order)}>
                                <span className="type">
                                    {orderTag(order.type)} {order.id}
                                </span>
                                <small className="time">{moment(order.date).format('DD-MM-YY hh:mm')}</small>
                                <small> {order.name}</small>
                            </li>
                        );
                    })}
                </ul>
            </Modal>
            <div className={cx('popup', { show: popup })}>
                <button onClick={handleClosePopup} className={cx('btn-close')}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
                <div className={cx('popup-container')}>
                    <div className={cx('popup-head')}>
                        <h3>Khách mới - BBQ</h3>
                        <p>{table.table_name}</p>
                    </div>
                    <div className={cx('popup-form')}>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field', { unvalid: name.length === 0 })}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faSignature} />
                                    </span>
                                    <span className={cx('input-label')}>Họ và Tên:</span>
                                    <span className={cx('input-value')}>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field', { unvalid: phone.length < 10 })}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faPhone} />
                                    </span>
                                    <span className={cx('input-label')}>Số điện thoại:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="text"
                                            value={phone}
                                            maxLength={10}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faPeopleGroup} />
                                    </span>
                                    <span className={cx('input-label')}>Người lớn:</span>
                                    <span className={cx('input-value')}>
                                        <div className={cx('input-row')}>
                                            <input type="text" value={adt} onChange={(e) => setAdt(e.target.value)} />
                                        </div>
                                    </span>
                                </div>
                            </label>
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faChildren} />
                                    </span>
                                    <span className={cx('input-label')}>Trẻ em:</span>
                                    <span className={cx('input-value')}>
                                        <div className={cx('input-row')}>
                                            <input type="text" value={chd} onChange={(e) => setChd(e.target.value)} />
                                        </div>
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <button className="btn-primary" onClick={handleClickNewOrderBbq}>
                                    Thêm
                                </button>
                            </div>
                            <div className="col">
                                <button className="btn-default" onClick={handleClosePopup}>
                                    Hủy
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Table;
