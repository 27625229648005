import classNames from 'classnames/bind';
import styles from './Camping.module.scss';
import { dateNow, formatPrice } from '~/utils/filters';
import { faCalendarDay, faComment, faMoneyBillTransfer, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as adminServices from '~/apiServices/adminServices';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BBQ_STATUS } from '~/utils/constants';
const cx = classNames.bind(styles);
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
import CampingAdd from './CampingAdd';
registerLocale('vi', vi);
function Camping() {
    const navigate = useNavigate();
    const nowDate = new Date();
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);
    const [checkIn, setCheckIn] = useState(nowDate);
    const [checkOut, setCheckOut] = useState(nextDate);
    const [dataList, setDataList] = useState([]);

    const handleGoDetail = (bbqId) => {
        navigate(`/super-admin/bbq/${bbqId}`);
    };

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleSearch = () => {
        console.log(checkIn, checkOut);
        if (checkIn === '' || checkOut === '' || null) return;
        const filterApi = async () => {
            const response = await adminServices.getCampings({
                params: {
                    check_in: moment(checkIn).format('YYYY-MM-DD'),
                    check_out: moment(checkOut).format('YYYY-MM-DD'),
                },
            });
            if (response) {
                setDataList(response);
            }
        };
        filterApi();
    };

    const handleAddNewCamping = () => {};
    return (
        <>
            <div className={cx('campings')}>
                <div className="page-title">
                    <div>
                        <strong>Danh sách đặt lều trại</strong>
                        <button className="btn-add-new" onClick={handleAddNewCamping}>
                            <FontAwesomeIcon icon={faSquarePlus} />
                        </button>
                    </div>
                    <span>{dateNow()}</span>
                </div>
                <CampingAdd />
                <div className={cx('campings-content')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faCalendarDay} />
                                    </span>
                                    <span className={cx('input-label')}>Ngày nhận:</span>
                                    <span className={cx('input-value')}>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={checkIn}
                                            onChange={(checkIn) => setCheckIn(checkIn)}
                                            placeholderText="Ngày nhận"
                                            locale="vi"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            shouldCloseOnSelect={true}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faCalendarDay} />
                                    </span>
                                    <span className={cx('input-label')}>Ngày trả:</span>
                                    <span className={cx('input-value')}>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={checkOut}
                                            onChange={(checkOut) => setCheckOut(checkOut)}
                                            placeholderText="Ngày trả"
                                            locale="vi"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            shouldCloseOnSelect={true}
                                        />
                                    </span>
                                </div>
                            </div>
                            <button className="btn-primary" onClick={handleSearch}>
                                Tìm
                            </button>
                        </div>
                    </div>
                    <div className={cx('camping-map')}>
                        <div className={cx('camping-row')}>
                            {dataList?.map((item, index) => {
                                return (
                                    <div key={index} className={cx('camping-item')}>
                                        {item.table_name}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Thông tin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList?.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        className={cx('tr-border')}
                                        onClick={() => handleGoDetail(item.bbq_id)}
                                    >
                                        <td className="text-center">#{item.bbq_id}</td>
                                        <td>
                                            <div className={cx('info')}>
                                                <div className={cx('data-group')}>
                                                    <div>
                                                        <strong>{item.table_name}</strong>
                                                        <br></br>
                                                        <strong style={{ color: 'var(--bg-primary)' }}>
                                                            {item.name}
                                                        </strong>
                                                        <p hidden={item.status === 0}>
                                                            <span className="label">
                                                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                                            </span>
                                                            <span style={{ color: '#ff5722' }}>
                                                                {formatPrice(item.deposit)}đ
                                                            </span>
                                                        </p>
                                                        <p hidden={item.status !== 2}>
                                                            <span className="label">
                                                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                                            </span>
                                                            <span style={{ color: '#ff5722' }}>
                                                                {formatPrice(item.total)}đ
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <span className={cx('datetime')}>
                                                        <span>{moment(item.date).format('DD-MM-YYYY')}</span>
                                                        <i style={{ color: '#ff5722' }}>{item.time}</i>
                                                        <strong className={cx('status', `status-${item.status}`)}>
                                                            {BBQ_STATUS[item.status]}
                                                        </strong>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={cx('note')}>
                                                <span className="label">
                                                    <FontAwesomeIcon icon={faComment} />
                                                </span>
                                                <span>{item.note}</span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Camping;
