import classNames from 'classnames/bind';
import styles from '../Store.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose, faSave } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';

const cx = classNames.bind(styles);
function StoreDetail({ item, side, setSide, onUpdate }) {
    const [ingredient, setIngredient] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [part, setPart] = useState(1);
    const [cost, setCost] = useState(0);

    useEffect(() => {
        console.log(ingredient);
        if (side === true) {
            setIngredient(item);
            const newPart = item.ingredient_part * quantity;
            setPart(newPart);
        }
    }, [side]);

    const handleCloseSide = () => {
        setSide(false);
    };

    const handldeChangeQuantity = (value) => {
        setQuantity(value);
        const newPart = ingredient.ingredient_part * value;
        setPart(newPart);
    };

    const handleStockIn = () => {
        const AddApi = async () => {
            const res = await adminServices.addStockIn({
                ingredien_id: Number(ingredient.ingredient_id),
                quantity: Number(quantity),
                part: Number(part),
                cost: Number(cost),
            });
            if (res.status) {
                const data = res.data;
                onUpdate(ingredient.ingredient_id, part);
                setQuantity(1);
                setPart(1);
                setCost(0);
            } else {
                alert(res.message);
            }
        };
        AddApi();
        setSide(false);
    };

    const handleBack = () => {
        setSide(false);
    };

    const handleSave = () => {
        setSide(false);
    };
    return (
        <>
            <div className={cx('right-sides', { show: side })}>
                <div className={cx('header')}>
                    <h3>NHẬP KHO</h3>
                    <p>{ingredient.ingredient_name}</p>
                    <p>
                        Tỉ lệ 1 {ingredient.ingredient_unit} = {ingredient.ingredient_part} Phần
                    </p>
                    <button onClick={handleCloseSide} className={cx('btn-close')}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Số lượng:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="number"
                                            min={1}
                                            value={quantity}
                                            onChange={(e) => handldeChangeQuantity(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                            <label>{ingredient.ingredient_unit}</label>
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Định lượng:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="number"
                                            min={1}
                                            value={part}
                                            onChange={(e) => setPart(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                            <label>phần</label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Định giá:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="number"
                                            min={1}
                                            value={cost}
                                            onChange={(e) => setCost(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <button className="btn-primary" onClick={handleStockIn}>
                                    Thêm
                                </button>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={handleBack}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-save')} onClick={handleSave}>
                                <span>
                                    <FontAwesomeIcon icon={faSave} />
                                </span>
                                <span>SAVE</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StoreDetail;
