import classNames from 'classnames/bind';
import styles from './NavAdmin.module.scss';
const cx = classNames.bind(styles);
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBurger, faChartLine, faGears, faList, faStore, faTent } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Modal from '../Modal';

function NavAdmin() {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('/super-admin');
    const [show, setShow] = useState(false);

    useEffect(() => {
        setActive(location.pathname);
    }, ['/']);

    const handleChangeNav = (active) => {
        setActive(active);
        navigate(active);
        setShow(false);
    };

    return (
        <>
            <Modal title={`ĐƠN HÀNG`} show={show} setShow={setShow}>
                <ul>
                    <li
                        className={cx('btn-bbqs', { active: active === '/super-admin/bbqs' })}
                        onClick={() => handleChangeNav('/super-admin/bbqs')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faBurger} />
                        </span>
                        <span>BBQ</span>
                    </li>
                    <li
                        className={cx('btn-camping', { active: active === '/super-admin/camping' })}
                        onClick={() => handleChangeNav('/super-admin/camping')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faTent} />
                        </span>
                        <span>CAMPING</span>
                    </li>
                    <li
                        className={cx('btn-tickets', { active: active === '/super-admin/tickets' })}
                        onClick={() => handleChangeNav('/super-admin/tickets')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faTent} />
                        </span>
                        <span>TICKET</span>
                    </li>
                    <li
                        className={cx('btn-bills', { active: active === '/super-admin/bills' })}
                        onClick={() => handleChangeNav('/super-admin/bills')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faTent} />
                        </span>
                        <span>BILL</span>
                    </li>
                </ul>
            </Modal>
            <div className={cx('navadmin')}>
                <div className={cx('navadmin-row')}>
                    <button
                        className={cx('btn-orders', { active: active === '/super-admin/orders' })}
                        onClick={() => handleChangeNav('/super-admin/orders')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faList} />
                        </span>
                        <span>ĐƠN HÀNG</span>
                    </button>
                    <button
                        className={cx('btn-admin', { active: active === '/super-admin' })}
                        onClick={() => handleChangeNav('/super-admin')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faChartLine} />
                        </span>
                        <span>THỐNG KÊ</span>
                    </button>
                    <button
                        className={cx('btn-stores', { active: active === '/super-admin/stores' })}
                        onClick={() => handleChangeNav('/super-admin/stores')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faStore} />
                        </span>
                        <span>KHO</span>
                    </button>
                    <button
                        className={cx('btn-setting', { active: active === '/super-admin/settings' })}
                        onClick={() => handleChangeNav('/super-admin/settings')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faGears} />
                        </span>
                        <span>CÀI ĐẶT</span>
                    </button>
                </div>
                <br></br>
            </div>
        </>
    );
}

export default NavAdmin;
