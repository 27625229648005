import classNames from 'classnames/bind';
import styles from '../Camping.module.scss';
const cx = classNames.bind(styles);
import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faBaby,
    faCalendarDay,
    faChild,
    faChildren,
    faClock,
    faPeopleGroup,
    faPhone,
    faSignature,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import vi from 'date-fns/locale/vi';
import { BbqContext } from '~/context/BbqContext';
registerLocale('vi', vi);
function CampingAdd() {
    const [adt, setAdt] = useState(1);
    const [chd, setChd] = useState(0);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const dateNow = new Date();
    const [date, setDate] = useState(dateNow);
    const [selectedHour, setSelectedHour] = useState('00');
    const [selectedMinute, setSelectedMinute] = useState('00');

    const handleAdtChange = (adt) => {
        setAdt(adt);
    };

    const handleChdChange = (chd) => {
        setChd(chd);
    };

    const handleNameChange = (name) => {
        setName(name);
    };

    const handlePhoneChange = (phone) => {
        setPhone(phone);
    };

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleAddBbq = () => {
        if (name.length === 0) {
            alert('Chưa nhập Họ và Tên');
            return;
        }

        if (phone.length === 0) {
            alert('Chưa nhập Số Điện Thoại');
            return;
        }

        if (phone.length < 10) {
            alert('Số Điện Thoại không đúng định dạng');
            return;
        }

        setBbqInfo({
            name: name,
            phone: phone,
            adt: adt,
            chd: chd,
            date: date,
            hour: `${selectedHour}`,
            minute: `${selectedMinute}`,
        });
        setStep(2);
    };
    return (
        <div className={cx('form')}>
            <div className="form-row">
                <label className="col">
                    <div className={cx('input-field', { unvalid: name.length === 0 })}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faSignature} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>Họ và Tên:</span>
                            <span className={cx('input-value')}>
                                <input type="text" value={name} onChange={(e) => handleNameChange(e.target.value)} />
                            </span>
                        </span>
                    </div>
                </label>
            </div>
            <div className="form-row">
                <label className="col">
                    <div className={cx('input-field', { unvalid: phone.length < 10 })}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faPhone} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>Số điện thoại:</span>
                            <span className={cx('input-value')}>
                                <input
                                    type="text"
                                    value={phone}
                                    maxLength={10}
                                    onChange={(e) => handlePhoneChange(e.target.value)}
                                />
                            </span>
                        </span>
                    </div>
                </label>
            </div>
            <div className="form-row">
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faPeopleGroup} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>NL:</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <input type="text" value={adt} onChange={(e) => handleAdtChange(e.target.value)} />
                                </div>
                            </span>
                        </span>
                    </div>
                </label>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faChildren} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>TE: (6-12)</span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <input type="text" value={chd} onChange={(e) => handleChdChange(e.target.value)} />
                                </div>
                            </span>
                        </span>
                    </div>
                </label>
                <label className="col">
                    <div className={cx('input-field')}>
                        <span className={cx('input-icon')}>
                            <FontAwesomeIcon icon={faChild} />
                        </span>
                        <span className={cx('input-content')}>
                            <span className={cx('input-label')}>
                                TE: <FontAwesomeIcon icon={faAngleLeft} /> 6
                            </span>
                            <span className={cx('input-value')}>
                                <div className={cx('input-row')}>
                                    <input type="text" value={chd} onChange={(e) => handleChdChange(e.target.value)} />
                                </div>
                            </span>
                        </span>
                    </div>
                </label>
            </div>
            <div className="form-row">
                <div className={cx('input-field')}>
                    <span className={cx('input-icon')}>
                        <FontAwesomeIcon icon={faCalendarDay} />
                    </span>
                    <span className={cx('input-content')}>
                        <span className={cx('input-label')}>Ngày nhận:</span>
                        <span className={cx('input-value')}>
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={date}
                                onChange={handleDateChange}
                                selectsStart
                                startDate={date}
                                placeholderText="Ngày đặt"
                                locale="vi"
                                readOnly
                            />
                        </span>
                    </span>
                </div>
                <div className={cx('input-field')}>
                    <span className={cx('input-icon')}>
                        <FontAwesomeIcon icon={faCalendarDay} />
                    </span>
                    <span className={cx('input-content')}>
                        <span className={cx('input-label')}>Ngày trả:</span>
                        <span className={cx('input-value')}>
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={date}
                                onChange={handleDateChange}
                                selectsStart
                                startDate={date}
                                placeholderText="Ngày đặt"
                                locale="vi"
                            />
                        </span>
                    </span>
                </div>
            </div>
            <div className="form-row">
                <div className="col">
                    <button className="btn-primary" onClick={handleAddBbq}>
                        Tiếp Tục
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CampingAdd;
