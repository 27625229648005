import classNames from 'classnames/bind';
import styles from '../Ingredient.module.scss';
import { formatPrice } from '~/utils/filters';
const cx = classNames.bind(styles);
function IngredientList({ datas }) {
    return (
        <>
            <div className={cx('ingredients')}>
                <table>
                    <thead>
                        <tr>
                            <th>Tên Nguyên Liệu</th>
                            <th>Đơn Vị</th>
                            <th>DL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.ingredient_name}</td>
                                    <td width={70}>
                                        <span className={cx('unit')}>1 {item.ingredient_unit}</span>
                                    </td>
                                    <td>
                                        <span className={cx('part')}>{item.ingredient_part}</span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export default IngredientList;
