import * as request from '~/utils/request';

export const getBbqs = async (params) => {
    try {
        const res = await request.get('admin/get_bbqs.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCategories = async (params) => {
    try {
        const res = await request.get('admin/get_categories.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getOrders = async (params) => {
    try {
        const res = await request.get('admin/get_orders.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getIngredients = async (params) => {
    try {
        const res = await request.get('admin/get_ingredients.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addIngredient = async (data) => {
    try {
        const res = await request.post('admin/add_ingredient.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getProducts = async (params) => {
    try {
        const res = await request.get('admin/get_products.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addProductIngredient = async (data) => {
    try {
        const res = await request.post('admin/add_product_ingredient.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const updateIngredient = async (data) => {
    try {
        const res = await request.post('admin/update_ingredient.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getStores = async (params) => {
    try {
        const res = await request.get('admin/get_stores.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addStockIn = async (data) => {
    try {
        const res = await request.post('admin/add_stock_in.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getBills = async (params) => {
    try {
        const res = await request.get('admin/bills/get_bills.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getBillDetail = async (params) => {
    try {
        const res = await request.get('admin/bills/get_detail.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getTickets = async (params) => {
    try {
        const res = await request.get('admin/ticket/get_tickets.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const removeTicket = async (data) => {
    try {
        const res = await request.post('admin/ticket/remove.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getLoyalCustomers = async (params) => {
    try {
        const res = await request.get('admin/customer/get_loyal_customers.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const removeCustomer = async (data) => {
    try {
        const res = await request.post('admin/customer/remove.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getCustomers = async (params) => {
    try {
        const res = await request.get('admin/customer/get_customers.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addCustomer = async (data) => {
    try {
        const res = await request.post('admin/customer/add.php', data);
        return res;
    } catch (error) {
        return error;
    }
};

export const getCampings = async (params) => {
    try {
        const res = await request.get('admin/camping/get_campings.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getWaters = async (params) => {
    try {
        const res = await request.get('admin/water/get_waters.php', params);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
