import classNames from 'classnames/bind';
import styles from '../../../Product.module.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSave } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '~/utils/filters';
import * as adminServices from '~/apiServices/adminServices';

const cx = classNames.bind(styles);
function IngredientItem({ item, onUpdate }) {
    const [editQ, setEditQ] = useState(false);
    const [q, setQ] = useState(0);

    const handleUpdateQuantity = (item) => {
        if (item.quantity === q) return setEditQ(false);
        const apiUpdate = async () => {
            const res = await adminServices.updateIngredient({
                action: 'product-ingredient',
                id: item.id,
                quantity: q,
            });
            if (res.status) {
                onUpdate(item.id, q);
            } else {
                alert(res.message);
            }
        };
        apiUpdate();
        setEditQ(false);
    };
    return (
        <>
            <tr key={item.ingredient_id}>
                <td>{item.ingredient_name}</td>
                <td width={150}>
                    <strong className={cx('quantity', { hide: editQ })}>
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            onClick={() => {
                                setEditQ(!editQ);
                                setQ(item.quantity);
                            }}
                        />{' '}
                        {formatPrice(item.quantity)}
                    </strong>
                    <span className={cx('edit-quantity', { hide: !editQ })}>
                        <input value={q} onChange={(e) => setQ(e.target.value)} />
                        <button onClick={() => handleUpdateQuantity(item)}>
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </span>
                </td>
                <td width={30}>
                    <span className={cx('unit')}>Phần</span>
                </td>
            </tr>
        </>
    );
}

export default IngredientItem;
