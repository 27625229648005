import classNames from 'classnames/bind';
import styles from './Ticket.module.scss';
import * as ticketServices from '~/apiServices/ticketServices';
import TicketList from '~/components/TicketList';
import Add from '~/components/TicketList/Add';
import { useEffect, useState } from 'react';
import { dateNow } from '~/utils/filters';
import TicketDetail from './TicketDetail';

const cx = classNames.bind(styles);
function Ticket() {
    const [side, setSide] = useState(false);
    const [ticketId, setTicketId] = useState(0);
    const [ticket, setTicket] = useState({});
    const [ticketList, setTicketList] = useState([]);
    const [printKey, setPrintKey] = useState(0);
    const fetchApi = async () => {
        const response = await ticketServices.getTickets();
        if (response) {
            setTicketList(response);
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);
    const handleAddNew = (newItem) => {
        setTicketId(newItem.ticket_id);
        setTicketList([newItem, ...ticketList]);
    };

    const handleUpdate = (ticketId, dataList) => {
        setTicketList((prevTicketList) => {
            const newTicketList = prevTicketList.map((obj) => {
                if (obj.ticket_id === ticketId) {
                    return { ...obj, discounts: dataList };
                }
                return obj;
            });
            return newTicketList;
        });
        setPrintKey(printKey + 1);
    };

    const handleClickDetail = (e, ticket) => {
        e.stopPropagation();
        setTicket(ticket);
        setSide(true);
    };
    return (
        <div className={cx('home')}>
            <h2 className="page-title">
                Bán Vé <span>{dateNow()}</span>
            </h2>
            <iframe
                key={printKey}
                id="iframe-ticket"
                src={`/print-ticket/${ticketId}`}
                style={{ display: 'none' }}
                title="PRINT TICKET"
            />
            <Add onAddNew={handleAddNew} />
            <TicketDetail item={ticket} side={side} setSide={setSide} onUpdate={handleUpdate} />
            <TicketList datas={ticketList} onClickDetail={handleClickDetail} />
        </div>
    );
}

export default Ticket;
