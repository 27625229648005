import classNames from 'classnames/bind';
import styles from './NavMain.module.scss';
const cx = classNames.bind(styles);
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBurger, faSitemap, faTicket } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function NavMain() {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('bbq');

    useEffect(() => {
        setActive(location.pathname);
    }, ['/']);

    const handleChangeNav = (active) => {
        setActive(active);
        navigate(active);
    };

    return (
        <>
            <div className={cx('navmain')}>
                <div className={cx('navmain-row')}>
                    <button className={cx('btn-tick', { active: active === '/' })} onClick={() => handleChangeNav('/')}>
                        <span>
                            <FontAwesomeIcon icon={faTicket} />
                        </span>
                        <span>TICKET</span>
                    </button>
                    <button
                        className={cx('btn-bbq', { active: active === '/bbq' })}
                        onClick={() => handleChangeNav('/bbq')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faBurger} />
                        </span>
                        <span>BBQ</span>
                    </button>
                    <button
                        className={cx('btn-table-map', { active: active === '/table-plan' })}
                        onClick={() => handleChangeNav('/table-plan')}
                    >
                        <span>
                            <FontAwesomeIcon icon={faSitemap} />
                        </span>
                        <span>Sơ Đồ Bàn</span>
                    </button>
                </div>
                <br></br>
            </div>
        </>
    );
}

export default NavMain;
