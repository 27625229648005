// export const CUSTOMER_TYPE = Object.freeze({
//     flower: 'Khách Vường hoa',
//     hotel: 'Khách Khách Sạn',
// });

export const CUSTOMER_TYPE = [
    { key: 'fit', label: 'K.Lẻ' },
    { key: 'bbq', label: 'BBQ' },
    { key: 'ticket', label: 'Vé' },
    { key: 'loyal', label: 'KH.Thân Thiết' },
];

export const CUSTOMER_TAB = [
    {
        key: 'new',
        label: 'MỚI',
        active: true,
    },
    {
        key: 'ordering',
        label: 'ĐANG ORDER',
        active: false,
    },
    {
        key: 'processing',
        label: 'CHẾ BIẾN',
        active: false,
    },
    {
        key: 'return',
        label: 'TRẢ MÓN',
        active: false,
    },
    {
        key: 'complete',
        label: 'HOÀN TẤT',
        active: false,
    },
];

export const ORDERS_TAB = [
    {
        key: 'orders',
        label: 'THỰC ĐƠN',
        active: true,
    },
    {
        key: 'discounts',
        label: 'DISCOUNTS',
        active: false,
    },
];

export const PRODUCTS = [
    {
        key: 'water',
        label: 'NƯỚC',
        active: true,
    },
    {
        key: 'food',
        label: 'ĐỒ ĂN',
        active: false,
    },
];

export const WATERS = [
    {
        key: 'tea',
        label: 'TRÀ',
        active: true,
    },
    {
        key: 'coffee',
        label: 'Coffee',
        active: false,
    },
    {
        key: 'beer',
        label: 'Bia & rượu',
        active: false,
    },
    {
        key: 'cream',
        label: 'Kem',
        active: false,
    },
    {
        key: 'juice',
        label: 'Nước ép',
        active: false,
    },
    {
        key: 'soda',
        label: 'Nước ngọt',
        active: false,
    },
    {
        key: 'forest',
        label: 'Đặc sản rừng',
        active: false,
    },
    {
        key: 'sea',
        label: 'Hải sản',
        active: false,
    },
    {
        key: 'vegetable',
        label: 'Rau củ',
        active: false,
    },
    {
        key: 'combo',
        label: 'Combo',
        active: false,
    },
    {
        key: 'beef',
        label: 'Bò',
        active: false,
    },
    {
        key: 'towel',
        label: 'Khăn',
        active: false,
    },
];

export const CART_STATUS = {
    0: 'Mới',
    1: 'Pha chế',
    2: 'Đã Pha xong',
};

export const ORDER_STATUS = {
    0: 'Mới',
    1: 'Đã Chốt Đơn',
};

export const PAYMENT_STATUS = {
    0: 'Chưa Thanh Toán',
    1: 'Đã Thanh Toán',
};

export const TABLE_STATUS = {
    0: 'Bàn Trống',
    1: 'Đang Có Khách',
};

export const BBQ_STATUS = {
    0: 'Mới',
    1: 'Đã Cọc',
    2: 'Hoàn Tất',
    3: 'Hủy Bàn',
};

export const BBQ_PAYMENT_PERCENT = 0.3;

export const TICKET_STATUS = {
    0: 'Chưa sử dụng',
    1: 'Đã sử dụng',
    2: 'Đã chốt',
};

export const PAYMENT_METHOD = [
    {
        value: 'transfer',
        label: 'Chuyển Khoản',
    },
    {
        value: 'cash',
        label: 'Tiền Mặt',
    },
];

export const ORDER_TAGS = [
    { key: 'fit', label: 'NƯỚC' },
    { key: 'bbq', label: 'BBQ' },
    { key: 'ticket', label: 'VÉ' },
];
