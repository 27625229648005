import classNames from 'classnames/bind';
import styles from './Ingredient.module.scss';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import IngredientList from './IngredientList';
import IngredientAdd from './IngredientList/IngredientAdd';
const cx = classNames.bind(styles);
function Ingredient() {
    const [ingredients, setIngredients] = useState([]);

    const fetchApi = async () => {
        const response = await adminServices.getIngredients();
        if (response) {
            setIngredients(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);
    const handleAddNew = (newItem) => {
        setIngredients([newItem, ...ingredients]);
    };
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">Nguyên Liệu</h2>
            </div>
            <div className={cx('admin-body')}>
                <IngredientAdd onAddNew={handleAddNew} />
                <IngredientList datas={ingredients} />
            </div>
        </>
    );
}

export default Ingredient;
