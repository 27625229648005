import classNames from 'classnames/bind';
import styles from './Product.module.scss';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import ProductList from './ProductList';
const cx = classNames.bind(styles);
function Product() {
    const [productList, setProductList] = useState([]);

    const fetchApi = async () => {
        const response = await adminServices.getProducts();
        if (response) {
            setProductList(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);
    const handleAddNew = (newItem) => {
        setProductList([newItem, ...productList]);
    };
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">Sản Phẩm</h2>
            </div>
            <div className={cx('admin-body')}>
                <ProductList datas={productList} />
            </div>
        </>
    );
}

export default Product;
