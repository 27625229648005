import classNames from 'classnames/bind';
import styles from '../../Product.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose, faSave } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import IngredientItem from './IngredientItem';

const cx = classNames.bind(styles);
function ProductDetail({ item, side, setSide }) {
    const [product, setProduct] = useState({});
    const [ingredientId, setIngredientId] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [options, setOptions] = useState([]);
    const [ingredientList, setIngredientList] = useState([]);

    const fetchApi = async () => {
        const response = await adminServices.getIngredients();
        if (response) {
            setOptions(response);
        }
    };
    useEffect(() => {
        fetchApi();
    }, []);

    useEffect(() => {
        if (side === true) {
            console.log(item);
            setProduct(item);
            setIngredientList(item.ingredients);
        }
    }, [side]);

    const handleCloseSide = () => {
        setSide(false);
    };

    const handleAddIngredient = () => {
        const AddApi = async () => {
            const res = await adminServices.addProductIngredient({
                product_id: Number(product.product_id),
                ingredient_id: Number(ingredientId),
                quantity: Number(quantity),
            });
            if (res.status) {
                const data = res.data;
                setIngredientList([...ingredientList, data]);
                setIngredientId(0);
                setQuantity(1);
            } else {
                alert(res.message);
            }
        };
        AddApi();
    };
    const handleUpdate = (id, q) => {
        setIngredientList((prevIngredientList) => {
            const newIngredientList = prevIngredientList.map((obj) => {
                if (obj.id === id) {
                    return { ...obj, quantity: q };
                }
                return obj;
            });

            return newIngredientList;
        });
    };
    const handleBack = () => {
        setSide(false);
    };

    const handleSave = () => {
        setSide(false);
    };
    return (
        <>
            <div className={cx('right-sides', { show: side })}>
                <div className={cx('header')}>
                    <h3>{product.product_name}</h3>
                    <button onClick={handleCloseSide} className={cx('btn-close')}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <div className={cx('body')}>
                    <div className={cx('form')}>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Nguyên liệu:</span>
                                    <span className={cx('input-value')}>
                                        <select value={ingredientId} onChange={(e) => setIngredientId(e.target.value)}>
                                            <option key={0} value={0}>
                                                --- Chọn nguyên liệu ---
                                            </option>
                                            {options.map((o) => {
                                                return (
                                                    <option key={o.ingredient_id} value={o.ingredient_id}>
                                                        {o.ingredient_name} ({o.ingredient_unit})
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="form-row">
                            <label className="col">
                                <div className={cx('input-field')}>
                                    <span className={cx('input-label')}>Số lượng:</span>
                                    <span className={cx('input-value')}>
                                        <input
                                            type="number"
                                            min={1}
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </span>
                                </div>
                            </label>
                            <button className="btn-primary" onClick={handleAddIngredient}>
                                Thêm
                            </button>
                        </div>
                        <div className="form-row">
                            <h4>Thành Phần:</h4>
                        </div>
                        <div className={cx('ingredients')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Tên</th>
                                        <th>SL</th>
                                        <th>DL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ingredientList?.map((item, index) => {
                                        return <IngredientItem key={index} item={item} onUpdate={handleUpdate} />;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={cx('footer')}>
                    <div className={cx('navbar')}>
                        <div className={cx('navbar-row')}>
                            <button className={cx('btn-back')} onClick={handleBack}>
                                <span>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                            </button>
                            <button className={cx('btn-save')} onClick={handleSave}>
                                <span>
                                    <FontAwesomeIcon icon={faSave} />
                                </span>
                                <span>SAVE</span>
                            </button>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetail;
