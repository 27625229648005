import classNames from 'classnames/bind';
import styles from '~/pages/Table/Table.module.scss';
const cx = classNames.bind(styles);
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as tableServices from '~/apiServices/tableServices';
import * as orderServices from '~/apiServices/orderServices';
import { dateNow, formatPrice, paymentName } from '~/utils/filters';
import Order from '~/components/Order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMoneyBillTransfer, faMoneyBill1 } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Modal from '~/components/Modal';
import { PAYMENT_METHOD } from '~/utils/constants';
import BoxLoading from '~/components/BoxLoading';
function TableDetail() {
    const navigate = useNavigate();
    const { tableId, objId, type } = useParams();
    const [show, setShow] = useState(false);
    const [detail, setDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [transfer, setTransfer] = useState(0);
    const [cash, setCash] = useState(0);
    useEffect(() => {
        const apiFetch = async () => {
            const data = await tableServices.getTable({
                params: {
                    type: type,
                    table_id: Number(tableId),
                    obj_id: Number(objId) || 0,
                },
            });
            if (data) {
                setDetail(data);
            }
        };
        apiFetch();
    }, []);

    const handleBeginOrder = () => {
        const apiUpdate = async () => {
            const orderId = await orderServices.bookingBegin({
                type: type,
                table_id: Number(tableId),
                obj_id: Number(objId) || 0,
            });
            if (orderId) navigate(`/table-booking/${type}/${tableId}/${objId || 0}/${orderId}`);
        };
        apiUpdate();
    };

    const handleEndOrder = (transfer, cash) => {
        //console.log(transfer, cash);
        setLoading(true);
        const apiUpdate = async () => {
            const response = await orderServices.bookingEnd({
                type: type,
                table_id: Number(tableId),
                obj_id: Number(objId) || 0,
                transfer: transfer,
                cash: cash,
            });
            if (response) {
                setDetail({ ...detail, orders: [] });
                setTimeout(() => {
                    setLoading(false);
                    navigate(`/table-plan`);
                }, 700);
            }
        };
        apiUpdate();
    };

    const handlePaymentTransfer = (e) => {
        e.stopPropagation();
        const newTranfer = detail.transfer + detail.remain;
        const newCash = detail.cash;
        //console.log('transfer', newTranfer, 'cash', newCash);
        handleEndOrder(newTranfer, newCash);
    };

    const handlePaymentCash = (e) => {
        e.stopPropagation();
        const newTranfer = detail.transfer;
        const newCash = detail.cash + detail.remain;
        //console.log('transfer', newTranfer, 'cash', newCash);
        handleEndOrder(newTranfer, newCash);
    };

    const handleShowModal = (e) => {
        setShow(true);
    };

    const handlePrint = () => {
        const f = document.getElementById('iframe-bill');
        const w = f.contentWindow;
        w.postMessage({ action: 'print-bill' }, f.src);
    };

    const handlePayment = (payment) => {
        console.log(payment);
        if (payment === 'transfer') {
            setDetail({ ...detail, payment_method: payment, transfer: detail.paid, cash: 0 });
        } else if (payment === 'cash') {
            setDetail({ ...detail, payment_method: payment, transfer: 0, cash: detail.paid });
        }
    };

    return (
        <div className="table-detail">
            <iframe
                id="iframe-bill"
                src={`/print-bill/${type}/${tableId}/${objId || 0}`}
                style={{ display: 'none' }}
                title="PRINT BILL"
            />
            <BoxLoading loading={loading} />
            <div className="page-title">
                <div className={cx('group-title')}>
                    <h3 className={cx('title')}>{detail.table_key}</h3>
                    <span className={cx('sub-title')}>{detail.table_name}</span>
                </div>
                <div className={cx('time')}>{dateNow()}</div>
                <div className={cx('btn-group')}>
                    <button className={cx('btn-booking-begin')} onClick={handleBeginOrder}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                    </button>

                    <button className={cx('btn-print-bill', { hide: detail.table_status === 0 })} onClick={handlePrint}>
                        In bill
                    </button>
                    <button
                        className={cx('btn-booking-end', { hide: detail.table_status === 0 })}
                        onClick={handleShowModal}
                    >
                        Đóng bàn
                    </button>
                </div>
            </div>

            <Modal title={`Chọn phương thức thanh toán`} show={show} setShow={setShow}>
                <ul>
                    <li className="payment tranfer" onClick={(e) => handlePaymentTransfer(e)}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faMoneyBillTransfer} />
                        </span>
                        <span className="text">CHUYỂN KHOẢN</span>
                    </li>
                    <li className="payment cash" onClick={(e) => handlePaymentCash(e)}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faMoneyBill1} />
                        </span>
                        <span className="text">TIỀN MẶT</span>
                    </li>
                </ul>
            </Modal>

            {type === 'fit' && detail.name !== undefined ? (
                <div className={cx('bbq-info')}>
                    <p>
                        <strong>{detail.name}</strong>
                    </p>
                </div>
            ) : (
                ''
            )}
            {type === 'bbq' && detail.name !== undefined ? (
                <div className={cx('bbq-info')}>
                    <p>
                        <strong>{detail.name}</strong> <strong>{detail.phone}</strong>
                    </p>
                    <p>
                        <span>
                            Cọc: <strong>{formatPrice(detail.deposit)}</strong>
                        </span>
                        <span>
                            <span>
                                Người Lớn: <strong>{detail.adt}</strong>
                            </span>
                            <span> </span>
                            <span>
                                Trẻ em: <strong>{detail.chd}</strong>
                            </span>
                        </span>
                    </p>
                    <p>
                        <span>
                            Ngày: <strong>{moment(detail.date).format('DD-MM-YYYY')}</strong>
                        </span>
                        <span>
                            Giờ:
                            <strong>
                                {detail.hour}:{detail.minute}
                            </strong>
                        </span>
                    </p>
                    <p>
                        <span>
                            Chú thích: <strong>{detail.note}</strong>
                        </span>
                        <span>
                            PT.T Toán: <strong>{paymentName('transfer')}</strong>
                        </span>
                    </p>
                </div>
            ) : (
                ''
            )}
            {type === 'ticket' && detail.ticket_phone !== undefined ? (
                <>
                    <div className={cx('bbq-info')}>
                        <p>
                            <span>
                                <span>{detail.ticket_name}: </span>
                                <strong className={cx('price')}>
                                    {formatPrice(detail.ticket_price * detail.ticket_number)}đ
                                </strong>
                            </span>
                            <span>
                                Số Khách: <strong>{detail.ticket_number}</strong>
                            </span>
                        </p>
                        <p>
                            <span>
                                SĐT: <strong>{detail.ticket_phone}</strong>
                            </span>
                            <span>
                                PT.T Toán: <strong>{paymentName(detail.payment_method)}</strong>
                            </span>
                        </p>
                        <p>
                            <span>
                                Chú thích: <strong>Miễn phí {detail.ticket_number} Ly nước</strong>
                            </span>
                        </p>
                    </div>
                    {detail.discounts?.length > 0 ? (
                        <div className={cx('loyal-customers')}>
                            <table>
                                <tbody>
                                    <tr className="trhead">
                                        <td colSpan={3}>KH.Thân Thiết</td>
                                    </tr>
                                    {detail.discounts?.map((item, index) => {
                                        return (
                                            <tr key={index} className={cx('tr-border')}>
                                                <td className="text-center">{item.customer_id}</td>
                                                <td>
                                                    <strong className={cx('name')}>{item.customer_name}</strong>
                                                </td>
                                                <td>
                                                    <strong className={cx('phone')}>{item.customer_phone}</strong>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                ''
            )}

            <Order datas={detail.orders || []} method={detail.payment_method} onPayment={handlePayment} />
        </div>
    );
}

export default TableDetail;
