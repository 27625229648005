import classNames from 'classnames/bind';
import styles from './Setting.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
const cx = classNames.bind(styles);
function Setting() {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('/settings');

    useEffect(() => {
        setActive(location.pathname);
    }, ['/']);

    const handleChangeNav = (active) => {
        setActive(active);
        navigate(active);
    };
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">Cài đặt</h2>
            </div>
            <div className={cx('admin-body')}>
                <div className={cx('row')}>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-ingredients', { active: active === '/super-admin/ingredients' })}
                            onClick={() => handleChangeNav('/super-admin/ingredients')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faClipboardList} />
                            </span>
                            <span>NGUYÊN LIỆU</span>
                        </button>
                    </div>
                    <div className={cx('col')}>
                        <button
                            className={cx('btn-products', { active: active === '/super-admin/products' })}
                            onClick={() => handleChangeNav('/super-admin/products')}
                        >
                            <span>
                                <FontAwesomeIcon icon={faProductHunt} />
                            </span>
                            <span>SẢN PHẨM</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;
