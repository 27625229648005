import classNames from 'classnames/bind';
import moment from 'moment';
import styles from '~/components/Table/Table.module.scss';
import { orderTag } from '~/utils/filters';
const cx = classNames.bind(styles);
function TableItem({ item, onClick }) {
    const handleClick = (tableId) => {
        onClick(tableId);
    };
    return (
        <>
            <div className={cx('table-item', `status-${item.table_status}`)} onClick={() => handleClick(item)}>
                <div className={cx('table-name')}>{item.table_name}</div>
                <ul className={cx('order-list')}>
                    {item.orders?.map((order, index) => {
                        return (
                            <li key={index} className={cx('customer-type', order.type)}>
                                <span className={cx('type')}>
                                    {orderTag(order.type)} {order.id}
                                </span>
                                <small className={cx('time')}>{moment(order.date).format('DD-MM-YY hh:mm')}</small>
                                <span>{order.name}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

export default TableItem;
