import classNames from 'classnames/bind';
import styles from '../Bill.module.scss';
import { customerLabel, formatPrice } from '~/utils/filters';
import { useState } from 'react';
import BillDetail from './BillDetail';

const cx = classNames.bind(styles);
function BillList({ datas }) {
    const [side, setSide] = useState(false);
    const [detail, setDetail] = useState({});

    const handleShowSide = (item) => {
        setSide(true);
        setDetail(item);
    };
    const totalPrice = datas.reduce((total, item) => total + item.transfer + item.cash, 0);
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Loại</th>
                        <th className="text-right">C.Khoản</th>
                        <th className="text-right">T.Mặt</th>
                        <th className="text-right">T.Tiền</th>
                    </tr>
                </thead>
                <tbody>
                    {datas?.map((item, index) => {
                        return (
                            <tr key={index} onClick={() => handleShowSide(item)}>
                                <td>{item.code}</td>
                                <td>
                                    {customerLabel(item.bill_type)} {item.obj_id}
                                </td>
                                <td className="text-right">
                                    <strong className={cx('transfer')}>{formatPrice(item.transfer)}</strong>
                                </td>
                                <td className="text-right">
                                    <strong className={cx('cash')}>{formatPrice(item.cash)}</strong>
                                </td>
                                <td className="text-right">
                                    <strong className={cx('price')}>{formatPrice(item.transfer + item.cash)}</strong>
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={3}>
                            <b>Tổng Cộng:</b>
                        </td>
                        <td className="text-right">
                            <strong className={cx('total')}>{datas?.length}</strong>
                        </td>
                        <td className="text-right">
                            <strong className={cx('total')}>{formatPrice(totalPrice)}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
            <BillDetail item={detail} side={side} setSide={setSide} />
        </>
    );
}

export default BillList;
