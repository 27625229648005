import classNames from 'classnames/bind';
import styles from './Store.module.scss';
import { formatPrice } from '~/utils/filters';
import { useEffect, useState } from 'react';
import * as adminServices from '~/apiServices/adminServices';
import StoreDetail from './StoreDetail';
const cx = classNames.bind(styles);
function Store() {
    const [stores, setStores] = useState([]);
    const [side, setSide] = useState(false);
    const [detail, setDetail] = useState({});

    const fetchStores = async () => {
        const response = await adminServices.getStores();
        if (response) {
            setStores(response);
        }
    };
    useEffect(() => {
        fetchStores();
    }, []);

    const handleShowSide = (item) => {
        setSide(true);
        setDetail(item);
    };
    const handleUpdateStock = (ingredienId, part) => {
        setStores((prevStores) => {
            const newStores = prevStores.map((obj) => {
                if (obj.ingredient_id === ingredienId) {
                    return { ...obj, store_part: part };
                }
                return obj;
            });

            return newStores;
        });
    };
    return (
        <>
            <div className={cx('home')}>
                <h2 className="page-title">Kho</h2>
            </div>
            <div className={cx('admin-body')}>
                <div className={cx('stores')}>
                    <table>
                        <thead>
                            <tr>
                                <th>Tên Nguyên Liệu</th>
                                <th>Tổng Nhập</th>
                                <th>Đã Dùng</th>
                                <th className="text-right">Tồn Kho</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stores?.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => handleShowSide(item)}>
                                        <td>{item.ingredient_name}</td>
                                        <td width={70}>
                                            <span className={cx('store')}>{item.store_part}</span>
                                        </td>
                                        <td>
                                            <span className={cx('used')}>{item.used_part}</span>
                                        </td>
                                        <td className="text-right">
                                            <span className={cx('available')}>
                                                {formatPrice(item.store_part - item.used_part)}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <StoreDetail item={detail} side={side} setSide={setSide} onUpdate={handleUpdateStock} />
            </div>
        </>
    );
}

export default Store;
